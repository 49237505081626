import React from 'react';

import HandCard from "../Card/HandCard";
import {ISortedBySuitHand, SuitEnum} from "../Types/interface";

interface Props {
    cards: ISortedBySuitHand;
}

function NorthSouthDummyHand(props: Props) {
    const suits = [SuitEnum.Spade, SuitEnum.Heart, SuitEnum.Club, SuitEnum.Diamond];

    return (
        <div className="ns-dummy-hand">
            { suits.map(suit =>
                <div className="ns-dummy-suit" key={suit}>
                    { props.cards[suit].map(c =>
                        <div
                            className="ns-dummy-card"
                            key={`${c.number}_${c.suit}`}>
                            <HandCard
                                card={c}
                            ></HandCard>
                        </div>)}
                </div>
            )}
        </div>
    );
}

export default NorthSouthDummyHand;
