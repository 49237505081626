import React, {useEffect, useState} from 'react';

import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";

interface Props {
    name: string | undefined;
    updateName: (name: string) => void;
}

function PlayerNameInput(props: Props) {
    const [name, setName] = useState("");

    function handleInputChange(event: any) {
        setName(event.target.value);
    }

    function handleClick() {
        props.updateName(name!);
    }

    useEffect(() => {
        setName(props.name || "");
    }, [ props.name ]);

    return (
        <InputGroup>
            <InputGroup.Prepend>
                <InputGroup.Text>Player's Name:</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
                name="name"
                value={name}
                onChange={handleInputChange}
            />
            <InputGroup.Append>
                <Button  onClick={() => handleClick()} variant="outline-secondary">Save</Button>
            </InputGroup.Append>
        </InputGroup>
    );
}

export default PlayerNameInput;