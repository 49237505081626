import {ICard, ITrick, PlayerPositionEnum, ISortedBySuitHand, SuitEnum} from "../Types/interface";

export function getCardsNotPlayed(hand: ICard[], playedTricks: ITrick[], player: PlayerPositionEnum) {
    const playedCards: ICard[] = [];
    playedTricks.forEach(trick => {
        if (trick.cards[player!]) {
            playedCards.push(trick.cards[player!]!);
        }
    });

    return hand.filter(card => {
        let unplayed = true;
        playedCards.forEach(playedCard => {
            if (playedCard!.number === card.number &&
                playedCard!.suit === card.suit) {
                unplayed = false;
            }
        });
        return unplayed;
    });
}

export function sortHand(hand: ICard[]) {
    return hand.sort((cardA, cardB) => {
        if ((cardA.suit > cardB.suit && !(cardA.suit === 1 && cardB.suit === 0)) || (cardA.suit === 0 && cardB.suit === 1)) {
            return -1 // "card A comes first"
        } else if (cardA.suit === cardB.suit) {
            return cardB.number - cardA.number;
        } else {
            return 1; // "card B comes first"
        }
    });
}

export function getSortedHandBySuit(hand: ICard[]) {
    const orderedHand = sortHand(hand);
    const bySuit: ISortedBySuitHand = {
        [SuitEnum.Spade]: [],
        [SuitEnum.Heart]: [],
        [SuitEnum.Diamond]: [],
        [SuitEnum.Club]: []
    };

    orderedHand.forEach(card => {
        bySuit[card.suit].push(card);
    })

    return bySuit;
}