import React, {useState} from 'react';

interface Props {
    options: IOption[];

    onSelect: (id: string) => void;
};

type IOption = {
    id: any;
    name: string;
    [key: string]: string;
}

function SelectList({options, onSelect}: Props) {
    const [selectedOption, setSelectedOption] = useState<null | string>(null);

    function optionSelected(optionId: string) {
        setSelectedOption(optionId);
        onSelect(optionId)
    }

    return (
        <div className="select-list">
            { options.map(option =>
                <div
                    className={`select-list-option ${option.id === selectedOption && "selected-option"}`}
                    onClick={() => optionSelected(option.id)}
                    key={option.id}>
                    {option.name}
                </div>
            )}
        </div>
    );
}

export default SelectList;