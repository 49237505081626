import React, {FunctionComponent, ReactNode} from 'react';
import {Logo} from "./Logo";
import Card from "react-bootstrap/Card";

type Props = {
    title: string;
    stepTitle: string;
    buttonBar: ReactNode;
}

export const WorkflowPortal: FunctionComponent<Props> = ({title, stepTitle, buttonBar, children}) => {
    return (
        <div className="workflow-portal">
            <Logo></Logo>
            <h3 className="workflow-portal-title">{title}</h3>
            <Card>
                <Card.Body className="workflow-portal-body">
                    <Card.Text className="text-center">{stepTitle}</Card.Text>
                    {children}
                    <div className="workflow-footer">
                        {buttonBar}
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default WorkflowPortal;