import {PlayerPositionEnum} from "./interface";

export class PlayerPosition {
    private readonly position: PlayerPositionEnum;

    constructor(position: PlayerPositionEnum) {
        this.position = position;
    }

    public getPartner() {
        switch(this.position) {
            case PlayerPositionEnum.North:
                return PlayerPositionEnum.South;
            case PlayerPositionEnum.South:
                return PlayerPositionEnum.North;
            case PlayerPositionEnum.East:
                return PlayerPositionEnum.West;
            case PlayerPositionEnum.West:
                return PlayerPositionEnum.East;
        }
    }

    public getPlayerToLeft() {
        switch(this.position) {
            case PlayerPositionEnum.North:
                return PlayerPositionEnum.East;
            case PlayerPositionEnum.South:
                return PlayerPositionEnum.West;
            case PlayerPositionEnum.East:
                return PlayerPositionEnum.South;
            case PlayerPositionEnum.West:
                return PlayerPositionEnum.North;
        }
    }

    public getPlayerToRight() {
        switch(this.position) {
            case PlayerPositionEnum.North:
                return PlayerPositionEnum.West;
            case PlayerPositionEnum.South:
                return PlayerPositionEnum.East;
            case PlayerPositionEnum.East:
                return PlayerPositionEnum.North;
            case PlayerPositionEnum.West:
                return PlayerPositionEnum.South;
        }
    }
}