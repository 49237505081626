import React from 'react';

import {CardNumberEnum, ICard} from "../Types/interface";

interface Props {
    hand: ICard[];
}

function HighCardPoints(props: Props) {
    let points = 0;
    props.hand.forEach(card => {
        switch(card.number) {
            case CardNumberEnum.Ace:
                points = points + 4;
                break;
            case CardNumberEnum.King:
                points = points + 3;
                break;
            case CardNumberEnum.Queen:
                points = points + 2;
                break;
            case CardNumberEnum.Jack:
                points = points + 1;
                break;
            default:
                break;
        }
    })

    return (
        <h6 className="remove-margin text-center secondary">HCP: {points}</h6>
    );
}

export default HighCardPoints;
