import React from 'react';

import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

import {IPlayers, PlayerPositionEnum} from '../Types/interface';

interface Props {
    player: PlayerPositionEnum;
    players: IPlayers;
    onPlayerPositionChange: (newPosition: PlayerPositionEnum) => void;
}

function PlayerPositionDropdown({player, players, onPlayerPositionChange}: Props) {

    function getName(position: PlayerPositionEnum) {
        const playersName = players[position];
        return playersName ? `${position} (${playersName})` : position;
    }

    return (
        <DropdownButton
            title={`${getName(player)}`}
            id="player-position"
            onSelect={(eventKey: PlayerPositionEnum) => onPlayerPositionChange(eventKey)}
            variant="primary"
            className="player-dropdown"
            alignRight
        >
            {
                Object.keys(PlayerPositionEnum).map(position => {
                    // @ts-ignore
                    return <Dropdown.Item eventKey={position} key={position}>{getName(position)}</Dropdown.Item>
                })
            }
        </DropdownButton>
    );
}

export default PlayerPositionDropdown;
