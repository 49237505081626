import React from 'react';

import {ICard, PlayerPositionEnum} from "../Types/interface";
import PlayerPositionDisplay from "../Game/PlayerPositionDisplay";
import TableCard from "../Card/TableCard";

interface Props {
    card?: ICard;
    playerName?: string;
    playerPosition: PlayerPositionEnum;
    colour?: string;
    inactive?: boolean;
}

function PlayersCard(props: Props) {
    return (
        <div className="players-card">
            <PlayerPositionDisplay
                className={`text-colour-${props.colour}`}
                player={props.playerPosition}
                playerName={props.playerName}
            ></PlayerPositionDisplay>
            <TableCard
                className={`border-colour-${props.colour} ${props.inactive ? "inactive" : ""}`}
                card={props.card}
            ></TableCard>
        </div>
    );
}

export default PlayersCard;

