import React from 'react';

import { PlayerPositionEnum} from "../Types/interface";

interface Props {
    player: PlayerPositionEnum;
    playerName?: string;
    className?: string;
    size?: "small" | "large";
}

function PlayerPositionDisplay(props: Props) {
    return (
        <div className={props.className}>
            <h5 className={`text-center remove-margin ${props.size === "large" ? "large-position" : ""}`}>{props.player}</h5>
            { props.playerName &&
                <p className={`text-center player-name ${props.size === "large" ? "large-name" : ""}`}>({props.playerName})</p>
            }

        </div>
    );
}

export default PlayerPositionDisplay;

