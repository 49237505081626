import React from 'react';

import {IContract, ITrick, PlayerPositionEnum} from "../Types/interface";
import {calculateTrickWinners} from "../Utils/contract-helper";

interface Props {
    contract: IContract;
    tricks: ITrick[];
}

function ContractResult(props: Props) {

    function getContractResult() {
        const {northSouthTricks, eastWestTricks} = calculateTrickWinners(props.tricks);
        const expectedTricks = props.contract.tricks;
        let actualTricks = 0;

        if (props.contract.player === PlayerPositionEnum.North || props.contract.player === PlayerPositionEnum.South) {
            actualTricks = northSouthTricks - 6;
        } else {
            actualTricks = eastWestTricks - 6;
        }

        if (actualTricks > expectedTricks) {
            return `Wahoo, contract was made! Making ${actualTricks - expectedTricks} more than needed.`;
        } else if (actualTricks === expectedTricks) {
            return `Nice, contract was exactly made!`;
        } else {
            return `Darn, didn't make the contract. Down ${Math.abs(actualTricks - expectedTricks)} tricks.`
        }
    }

    return (
        <p className="primary">{getContractResult()}</p>
    );
}

export default ContractResult;