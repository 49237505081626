import {IPlayers, PlayerPositionEnum} from "../Types/interface";

export class LocalStorageService {
    private readonly PLAYER_POSITION = "Player_Position";
    private readonly PLAYER_NAME = "Player_Name";

    public getPlayerPosition(players: IPlayers): PlayerPositionEnum | null {
        const position = localStorage.getItem(this.PLAYER_POSITION) as PlayerPositionEnum;
        const name = localStorage.getItem(this.PLAYER_NAME);

        return name !== players[position] ? null : position;
    }

    savePlayerPosition(newPosition: PlayerPositionEnum) {
        localStorage.setItem(this.PLAYER_POSITION, newPosition);
    }

    savePlayerName(newName: string) {
        localStorage.setItem(this.PLAYER_NAME, newName);
    }

}