import PlayerPositionDisplay from "../Game/PlayerPositionDisplay";
import HighCardPoints from "./HighCardPoints";
import NotPlayableHand, {HandTypes} from "../Hand/NotPlayableHand";
import React from "react";
import {IDeal, IPlayers, PlayerPositionEnum} from "../Types/interface";
import {PlayerPosition} from "../Types/PlayerPosition";

interface Props {
    players: IPlayers,
    player: PlayerPositionEnum,
    deal: IDeal
}

function AllHands(props: Props) {
    const activePlayer = new PlayerPosition(props.player);

    return (
        <div className="playing-grid">
            <div className="east-west west column">
                <PlayerPositionDisplay
                    player={activePlayer.getPlayerToLeft()}
                    playerName={props.players[activePlayer.getPlayerToLeft()]}
                ></PlayerPositionDisplay>
                <HighCardPoints
                    hand={props.deal[activePlayer.getPlayerToLeft()]}
                ></HighCardPoints>
                <NotPlayableHand
                    type={HandTypes.EastWestDummy}
                    hand={props.deal[activePlayer.getPlayerToLeft()]}
                ></NotPlayableHand>
            </div>
            <div className="north-south">
                <div className="north">
                    <PlayerPositionDisplay
                        player={activePlayer.getPartner()}
                        playerName={props.players[activePlayer.getPartner()]}
                    ></PlayerPositionDisplay>
                    <HighCardPoints
                        hand={props.deal[activePlayer.getPartner()]}
                    ></HighCardPoints>
                    <NotPlayableHand
                        type={HandTypes.NorthSouthDummy}
                        hand={props.deal[activePlayer.getPartner()]}
                    ></NotPlayableHand>
                </div>
                <div>
                    <PlayerPositionDisplay
                        player={props.player}
                        playerName={props.players[props.player]}
                    ></PlayerPositionDisplay>
                    <HighCardPoints
                        hand={props.deal[props.player]}
                    ></HighCardPoints>
                    <NotPlayableHand
                        type={HandTypes.NorthSouthDummy}
                        hand={props.deal[props.player]}
                    ></NotPlayableHand>
                </div>
            </div>
            <div className="east-west east column">
                <PlayerPositionDisplay
                    player={activePlayer.getPlayerToRight()}
                    playerName={props.players[activePlayer.getPlayerToRight()]}
                ></PlayerPositionDisplay>
                <HighCardPoints
                    hand={props.deal[activePlayer.getPlayerToRight()]}
                ></HighCardPoints>
                <NotPlayableHand
                    type={HandTypes.EastWestDummy}
                    hand={props.deal[activePlayer.getPlayerToRight()]}
                ></NotPlayableHand>
            </div>
        </div>
    );
}

export default AllHands;