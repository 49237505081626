import React from 'react';

import {ICard, PlayerPositionEnum, ITrick, IPlayers, ISortedBySuitHand, SuitEnum} from "../Types/interface";
import StraightHand from "./StraightHand";
import {getCardsNotPlayed, getSortedHandBySuit, sortHand} from "../Utils/hand-helper";
import WrongTurn from "../Alerts/WrongTurn";
import Alert from "react-bootstrap/Alert";

type Props = {
    hand: ICard[]
    player: PlayerPositionEnum,
    tricks: ITrick[],
    playerWithTurn: PlayerPositionEnum,
    players: IPlayers,
    playedCard: (card: ICard) => void
}

type State = {
    showTurnAlert: boolean,
    showCardSuitAlert: boolean
}

class PlayableHand extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            showTurnAlert: false,
            showCardSuitAlert: false
        }
    }

    static getDerivedStateFromProps(nextProps: Props, prevState: State): Partial<State> | null {
        if (nextProps.playerWithTurn === nextProps.player && prevState.showTurnAlert) {
            return { showTurnAlert: false };
        }
        return null;
    }

    public handlePlayedCard(card: ICard, hand: ISortedBySuitHand) {
        if (this.props.player === this.props.playerWithTurn) {
            const lastTrick = this.props.tricks[this.props.tricks.length - 1];
            if (lastTrick.lead !== undefined && lastTrick.winner === undefined) {
                const leadSuit = lastTrick.cards[lastTrick.lead]!.suit;
                const numLeadSuits = hand[leadSuit];
                if (leadSuit === card.suit || numLeadSuits.length === 0) {
                    this.props.playedCard(card);
                    this.setState({showCardSuitAlert: false});
                } else {
                    this.setState({showCardSuitAlert: true});
                }
            } else {
                this.props.playedCard(card);
            }
        } else {
            this.setState({showTurnAlert: true});
        }
    }

    render() {
        const cardsInHand = getCardsNotPlayed(this.props.hand, this.props.tricks, this.props.player);
        const sortedHand = sortHand(cardsInHand);
        const sortedBySuit = getSortedHandBySuit(cardsInHand);

        const lastTrick = this.props.tricks[this.props.tricks.length - 1];

        return (
            <div>
                {this.state.showTurnAlert &&
                <WrongTurn
                    players={this.props.players}
                    playerWithTurn={this.props.playerWithTurn}
                    closeAlert={() => this.setState({ showTurnAlert: false })}
                ></WrongTurn>
                }
                {this.state.showCardSuitAlert &&
                <Alert variant="warning" onClose={() => this.setState({ showCardSuitAlert: false })} dismissible>
                    {`You must follow suit. Please play a ${SuitEnum[lastTrick.cards[lastTrick.lead!]!.suit]}.`}
                </Alert>
                }
                <StraightHand
                    cards={sortedHand}
                    playedCard={(card => this.handlePlayedCard(card, sortedBySuit))}
                ></StraightHand>
            </div>
        );
    }
}

export default PlayableHand;
