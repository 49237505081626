import React from 'react';

import {ICard, IHand, IPlayers, PlayerPositionEnum} from "../Types/interface";
import {PlayerPosition} from "../Types/PlayerPosition";
import ContractProgress from "./ContractProgress";
import PlayersCard from "./PlayersCard";
import NotPlayableHand, {HandTypes} from "../Hand/NotPlayableHand";
import PlayableHand from "../Hand/PlayableHand";

interface Props {
    player: PlayerPositionEnum;
    hand: IHand;
    players: IPlayers;
    playedCard: (player: PlayerPositionEnum, card: ICard) => void;
};

class Play extends React.Component<Props, {}> {

    private calculateNextPlayersTurn(game: IHand): PlayerPositionEnum {
        const currentTrick = game.tricks[game.tricks.length - 1]!;

        if (game.tricks.length === 1 && !currentTrick.lead) {
            const declarer = new PlayerPosition(game.contract!.player);
            return declarer.getPlayerToLeft();
        } else {
            let nextPlayer = currentTrick.lead!;
            for (let i = 0; i < 4; i++) {
                if (!currentTrick.cards[nextPlayer]) {
                    return nextPlayer;
                }
                nextPlayer = (new PlayerPosition(nextPlayer)).getPlayerToLeft();
            }

            return currentTrick.winner!;
        }
    }

    private getPlayingColourClass(player: PlayerPositionEnum) {
        const nextMove = this.calculateNextPlayersTurn(this.props.hand);
        const currentTrick = this.props.hand.tricks[this.props.hand.tricks.length - 1];

        if (nextMove === player) {
            if (currentTrick.winner) {
                return `${this.props.hand.tricks.length + 1}`
            } else {
                return `${this.props.hand.tricks.length}`
            }
        }

        return "";
    }

    render() {
        const activePlayer = new PlayerPosition(this.props.player);
        const currentTrick = this.props.hand.tricks[this.props.hand.tricks.length - 1];
        const playerWithTurn = this.calculateNextPlayersTurn(this.props.hand);

        const declarer = new PlayerPosition(this.props.hand.contract!.player);

        const dummy = declarer.getPartner();
        const showDummy = !!this.props.hand.tricks[0].lead;

        const inactive = currentTrick.winner !== undefined;

        return (
            <>
                <ContractProgress contract={this.props.hand.contract!} tricks={this.props.hand.tricks}></ContractProgress>
                {
                    showDummy && dummy === activePlayer.getPartner() &&
                    <PlayableHand
                        hand={this.props.hand.deal[activePlayer.getPartner()]}
                        player={activePlayer.getPartner()}
                        playerWithTurn={playerWithTurn}
                        players={this.props.players}
                        tricks={this.props.hand.tricks}
                        playedCard={(card) => this.props.playedCard(activePlayer.getPartner(), card)}
                    ></PlayableHand>
                }
                <div className="playing-grid">
                    <div className="east-west west">
                        {
                            showDummy && dummy === activePlayer.getPlayerToLeft() &&
                            <NotPlayableHand
                                type={HandTypes.EastWestDummy}
                                hand={this.props.hand.deal[activePlayer.getPlayerToLeft()]}
                                tricks={this.props.hand.tricks}
                                player={activePlayer.getPlayerToLeft()}
                            ></NotPlayableHand>
                        }
                        <PlayersCard
                            inactive={inactive}
                            playerPosition={activePlayer.getPlayerToLeft()}
                            playerName={this.props.players[activePlayer.getPlayerToLeft()]}
                            card={currentTrick.cards[activePlayer.getPlayerToLeft()]}
                            colour={this.getPlayingColourClass(activePlayer.getPlayerToLeft())}
                        ></PlayersCard>
                    </div>
                    <div className="north-south">
                        <div className="north">
                            <PlayersCard
                                inactive={inactive}
                                playerPosition={activePlayer.getPartner()}
                                playerName={this.props.players[activePlayer.getPartner()]}
                                card={currentTrick.cards[activePlayer.getPartner()]}
                                colour={this.getPlayingColourClass(activePlayer.getPartner())}
                            ></PlayersCard>
                        </div>
                        <PlayersCard
                            inactive={inactive}
                            playerPosition={this.props.player}
                            playerName={this.props.players[this.props.player]}
                            card={currentTrick.cards[this.props.player]}
                            colour={this.getPlayingColourClass(this.props.player)}
                        ></PlayersCard>
                    </div>
                    <div className="east-west east">
                        <PlayersCard
                            inactive={inactive}
                            playerPosition={activePlayer.getPlayerToRight()}
                            playerName={this.props.players[activePlayer.getPlayerToRight()]}
                            card={currentTrick.cards[activePlayer.getPlayerToRight()]}
                            colour={this.getPlayingColourClass(activePlayer.getPlayerToRight())}
                        ></PlayersCard>
                        {
                            showDummy && dummy === activePlayer.getPlayerToRight() &&
                            <NotPlayableHand
                                type={HandTypes.EastWestDummy}
                                hand={this.props.hand.deal[activePlayer.getPlayerToRight()]}
                                tricks={this.props.hand.tricks}
                                player={activePlayer.getPlayerToRight()}
                            ></NotPlayableHand>
                        }
                    </div>
                </div>
                {
                    showDummy && dummy === this.props.player ?
                        <NotPlayableHand
                            type={HandTypes.NorthSouthDummy}
                            hand={this.props.hand.deal[this.props.player]}
                            tricks={this.props.hand.tricks}
                            player={this.props.player}
                        ></NotPlayableHand>
                        :
                        <PlayableHand
                            hand={this.props.hand.deal[this.props.player]}
                            player={this.props.player}
                            playerWithTurn={playerWithTurn}
                            players={this.props.players}
                            tricks={this.props.hand.tricks}
                            playedCard={(card) => this.props.playedCard(this.props.player, card)}
                        ></PlayableHand>
                }
            </>);
    }
}

export default Play;