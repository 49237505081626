import React from 'react';

import {IContract} from "../Types/interface";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ContractAsText from "../Game/ContractAsText";

interface Props {
    contract: IContract;

    playHand: () => void;
}

function BiddingCompleteAlert({contract, playHand}: Props) {

    return (
        <Alert variant="success">
            <Alert.Heading>Contract Determined!</Alert.Heading>
            <p>The contract is <ContractAsText contract={contract}></ContractAsText>.</p>
            <div className="d-flex justify-content-end">
                <Button onClick={() => playHand()} variant="outline-success">
                    Play Hand!
                </Button>
            </div>
        </Alert>
    );
}

export default BiddingCompleteAlert;

