import React, {useState} from 'react';
import ChooseTablePosition from "./ChooseTablePosition";
import WorkflowPortal from "../Components/WorkflowPortal";
import {ITable, PlayerPositionEnum} from "../Types/interface";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";

enum Steps {
    SELECT_POSITION,
    SET_NAME
}

type Props = {
    table: ITable;
    joined: (position: PlayerPositionEnum, name: string | null) => void;
}

function JoinTable({table, joined}: Props) {
    const [tablePosition, setTablePosition] = useState<null | PlayerPositionEnum>(null);
    const [name, setName] = useState<null | string>(null);
    const [step, setStep] = useState(Steps.SELECT_POSITION);
    const [showAlert, setShowAlert] = useState(false);

    function submitTablePosition() {
        if (tablePosition !== null) {
            setShowAlert(false);
            setStep(Steps.SET_NAME);
        } else {
            setShowAlert(true);
        }
    }

    function joinTable() {
        joined(tablePosition!, name);
    }

    const steps = [
        {
            title: "Where do you want to sit?",
            body: (
                <>
                    {showAlert &&
                    <Alert variant="danger">Please click a table position.</Alert>
                    }
                    <ChooseTablePosition
                        players={table.players}
                        tablePositionSelected={(position) => {setTablePosition(position)}}
                    ></ChooseTablePosition>
                </>),
            footer: <Button variant="primary" onClick={() => submitTablePosition()}>Next</Button>
        },
        {
            title: `${table.players[tablePosition!] ? "Update your name?" : "What is your name?"}`,
            body: <Form>
                <Form.Group controlId="name">
                    <Form.Label>Name (Optional)</Form.Label>
                    <Form.Control
                        placeholder={"Enter name"}
                        defaultValue={table.players[tablePosition!]}
                        onChange={(event) => setName(event.target.value)} />
                </Form.Group>
            </Form>,
            footer: <Button variant="primary" onClick={() => joinTable()}>Join</Button>
        }
    ];

    return (
        <WorkflowPortal
            title="Join the table"
            stepTitle={steps[step].title}
            buttonBar={steps[step].footer}
        >
            {steps[step].body}
        </WorkflowPortal>
    );
}

export default JoinTable;