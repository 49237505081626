import React from 'react';

import {DoubleBids, IContract, NoTrumpEnum} from "../Types/interface";
import SuitIcon from "../Card/SuitIcon";

interface Props {
    contract: IContract;
}

function ContractAsText({contract}: Props) {

    function getDoublePhrase() {
        if (contract.double === DoubleBids.DOUBLE) {
            return " Doubled";
        } else if (contract.double === DoubleBids.DOUBLE_DOUBLE) {
            return " Redoubled"
        }
        return "";
    }

    if (contract.suit === NoTrumpEnum.NoTrump) {
        return <p className="remove-margin inline-block">{`${contract.tricks} NT ${contract.player} ${getDoublePhrase()}`}</p>;
    } else {
        return (<div className="inline-block">
            <p className="remove-margin">{contract.tricks}
            <SuitIcon
                className="cp-contract-icon"
                suit={contract.suit}/>
                {contract.player} {getDoublePhrase()}</p>
        </div>);
    }
}

export default ContractAsText;

