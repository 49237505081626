import React from 'react';

import {IBid, IDeal, IPlayers, PlayerPositionEnum} from "../Types/interface";
import NotPlayableHand, {HandTypes} from "../Hand/NotPlayableHand";
import BiddingGrid from "./BiddingGrid";
import BiddingBox from "./BiddingBox";
import {PlayerPosition} from "../Types/PlayerPosition";
import PlayerNameDisplay from "../Game/PlayerNameDisplay";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

interface Props {
    player: PlayerPositionEnum;
    players: IPlayers;
    hands: IDeal;
    dealer: PlayerPositionEnum;
    bids: IBid[] | null;
    disabled: boolean;

    addBid: (bid: IBid) => void;
}

function Bid({player, players, hands, dealer, bids, disabled, addBid}: Props) {
    let nextBidder: PlayerPositionEnum;
    if (bids) {
        const lastBidder = bids[bids.length - 1].player;
        nextBidder = new PlayerPosition(lastBidder).getPlayerToLeft();
    } else {
        nextBidder = dealer;
    }

    const biddingGrid = <BiddingGrid
        className="grow"
        player={player}
        players={players}
        dealer={dealer}
        bids={bids}
        turn={nextBidder}
    ></BiddingGrid>;

    const playersHand = <>
        <PlayerNameDisplay
            player={player}
            playerName={players[player]}
            size="small"
        ></PlayerNameDisplay>
        <NotPlayableHand
            type={HandTypes.Straight}
            hand={hands[player]}
        ></NotPlayableHand>
    </>;

    const biddingBox = <BiddingBox
        player={player}
        players={players}
        playerWithTurn={nextBidder}
        bids={bids}
        onBidClick={(bid) => addBid(bid)}
        disabled={disabled}
    ></BiddingBox>

    return (
        <>
            <Container className="desktop">
                <Row>
                    <Col xl="8" className="flex-column">
                        {biddingGrid}
                        {playersHand}
                    </Col>
                    <Col xl="4">
                        {biddingBox}
                    </Col>
                </Row>
            </Container>
            <Container className="tablet mobile">
                <Row>
                    <Col md={{span: 6, order: 1}} sm={{span: 12, order: 1}} xs={{span: 12, order: 1}}>
                        {biddingGrid}
                    </Col>
                    <Col md={{span: 6, order: 2}} sm={{span: 12, order: 3}} xs={{span: 12, order: 3}}>
                        <h5 className="mobile text-center">Bidding Box</h5>
                        {biddingBox}
                    </Col>
                    <Col md={{span: 12, order: 3}} sm={{span: 12, order: 2}} xs={{span: 12, order: 2}} className="bid-players-hand">
                        {playersHand}
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Bid;