import React from 'react';

import {PlayerPositionEnum} from "../Types/interface";

interface Props {
    player: PlayerPositionEnum;
    playerName?: string;
    className?: string;
    size?: "small" | "large";
}

function PlayerNameDisplay(props: Props) {
    return (
        <div className={props.className}>
            { props.playerName &&
                <>
                    <h5 className={`text-center remove-margin ${props.size === "large" ? "large-position" : ""}`}>{props.playerName}'s Hand</h5>
                    <p className={`text-center player-name ${props.size === "large" ? "large-name" : ""}`}>({props.player})</p>
                </>
            }
            {
                !props.playerName &&
                    <h5 className={`text-center remove-margin ${props.size === "large" ? "large-position" : ""}`}>{props.player}'s Hand</h5>
            }
        </div>
    );
}

export default PlayerNameDisplay;