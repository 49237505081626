import React from 'react';
import Bid from "../Bid/Bid";
import Play from "../Play/Play";
import Review from "../Review/Review";
import {
    DoubleBids,
    IBid,
    ICard,
    IContract, IContractBid,
    IHand,
    IOtherBid,
    IPlayers, ITable,
    ITrick,
    NoTrumpEnum, PassBid,
    PlayerPositionEnum
} from "../Types/interface";
import {HandService} from "../Services/hand.service";
import {isContractBid, isOtherBid} from "../Utils/common";
import {PlayerPosition} from "../Types/PlayerPosition";
import BiddingCompleteAlert from "../Alerts/BiddingCompleteAlert";
import PlayingCompleteAlert from "../Alerts/PlayingCompleteAlert";
import {BrandLoadingIndicator} from "../Components/BrandLoadingIndicator";

interface Props {
    player: PlayerPositionEnum;
    players: IPlayers;
    table: ITable;
}

interface State {
    hand: IHand | null;
    handStage: HandStage | null;
}

enum HandStage {
    BIDDING = "Bidding",
    PLAYING = "Playing",
    REVIEWING = "Review"
}

class Table extends React.Component<Props, State> {
    private readonly NUM_TRICKS = 13;
    
    private handService: HandService;
    
    constructor(props: Props) {
        super(props);
        
        this.handService = new HandService();
        
        this.state = {
            handStage: null,
            hand: null
        }
    }

    componentDidMount() {
        this.handService.setHandToActive(this.props.table);
        this.handService.getHand().subscribe(hand => {
            this.setState({hand});

            if (this.state.handStage === null) {
                this.setState({ handStage: this.getHandStage() });
            }
        });
    }
    
    private playedCard(player: PlayerPositionEnum, card: ICard) {
        const tricks = this.state.hand!.tricks;
        let currentTrick = tricks[tricks.length - 1];

        if (currentTrick.winner) {
            tricks.push({ cards: {} });
            currentTrick = tricks[tricks.length - 1];
        }

        if (!currentTrick.lead) {
            currentTrick.lead = player;
        }

        currentTrick.cards[player] = card;

        if (currentTrick.cards.North && currentTrick.cards.East && currentTrick.cards.South && currentTrick.cards.West) {
            currentTrick.winner = this.calculateWinner(currentTrick, this.state.hand!.contract!);
        }

        this.handService.updateTricks(this.props.table, this.state.hand!.id, tricks);

        if (this.getHandStage() === HandStage.REVIEWING) {
            this.handService.handComplete(this.props.table, this.state.hand!.id, this.state.hand!.dealer)
        }
    }

    private calculateWinner(trick: ITrick, contract: IContract): PlayerPositionEnum {
        const trumpSuit = contract.suit;

        let winner = trick.lead!;
        const positions = [PlayerPositionEnum.North, PlayerPositionEnum.South, PlayerPositionEnum.East, PlayerPositionEnum.West];
        positions.forEach((comparePosition) => {
            const winnerCard = trick.cards[winner]!;
            const compareCard = trick.cards[comparePosition]!;

            if (winnerCard.suit === compareCard.suit)
                winner = winnerCard.number > compareCard.number ? winner : comparePosition;
            else if (trumpSuit !== NoTrumpEnum.NoTrump) {
                if (winnerCard.suit !== trumpSuit && compareCard.suit === trumpSuit) {
                    winner = comparePosition;
                }
            }
        });

        return winner;
    }

    private getHandStage(): HandStage {
        if (!this.state.hand!.contract) {
            return HandStage.BIDDING;
        } else if (this.state.hand!.tricks.length === this.NUM_TRICKS && this.state.hand!.tricks[this.NUM_TRICKS - 1].winner) {
            return HandStage.REVIEWING;
        } else {
            return HandStage.PLAYING;
        }
    }

    private saveNewBid(bid: IBid) {
        let newBids;
        if (!this.state.hand!.bids) {
            newBids = [bid];
        } else {
            newBids = [...this.state.hand!.bids, bid];
        }

        this.handService.updateBids(this.props.table, this.state.hand!.id, newBids);

        if (this.biddingComplete(newBids)) {
            this.setContract(this.determineContract(newBids));
        }
    }

    private setContract(newContract: IContract) {
        this.handService.updateContract(this.props.table, this.state.hand!.id, newContract);
    }

    private biddingComplete(bids: IBid[]) {
        if (bids.length >= 4) {
            const lastBid = bids[bids.length - 1] as IOtherBid;
            const secondLastBid = bids[bids.length - 2] as IOtherBid;
            const thirdLastBid = bids[bids.length - 3] as IOtherBid;

            if (lastBid.otherBid === PassBid.PASS && secondLastBid.otherBid === PassBid.PASS &&
                thirdLastBid.otherBid === PassBid.PASS) {
                return true;
            }
        }
        return false;
    }

    private determineContract(bids: IBid[]): IContract {
        let contract: IContract | null = null;
        let double = null;

        for (let i = bids.length - 1; i >= 0; i--) {
            if (isContractBid(bids[i])) {
                const bid = bids[i] as IContractBid;

                if (contract === null) {
                    contract = {
                        suit: bid.suit,
                        player: bid.player,
                        tricks: bid.tricks,
                        double: null
                    } ;
                } else if (contract.suit === bid.suit) {
                    const partner = new PlayerPosition(contract.player).getPartner();
                    if (bid.player === contract.player || bid.player === partner) {
                        contract.player = bid.player;
                    }
                }
            } else if (isOtherBid(bids[i]) && contract === null) {
                const otherBid = bids[i] as IOtherBid;
                if (double === null && (
                    otherBid.otherBid === DoubleBids.DOUBLE || otherBid.otherBid === DoubleBids.DOUBLE_DOUBLE)) {
                    double = otherBid.otherBid;
                }
            }
        }

        if (contract !== null) {
            return {
                ...contract,
                double
            };
        }

        throw new Error("Cannot determine contract");
    }

    private playNextHand() {
        this.setState({handStage: null});
        this.handService.setHandToActive(this.props.table);
    }

    private getComponentByState(stageComplete: boolean) {
        switch (this.state.handStage) {
            case HandStage.BIDDING:
                return (
                    <Bid
                        player={this.props.player}
                        players={this.props.players}
                        hands={this.state.hand!.deal}
                        dealer={this.state.hand!.dealer}
                        bids={this.state.hand!.bids}
                        addBid={(bid) => this.saveNewBid(bid)}
                        disabled={stageComplete}
                    ></Bid>
                );
            case HandStage.PLAYING:
                return (
                    <Play
                        player={this.props.player}
                        hand={this.state.hand!}
                        players={this.props.players}
                        playedCard={(player, card) => this.playedCard(player, card)}
                    ></Play>
                );
            case HandStage.REVIEWING:
                return (
                    <Review
                        player={this.props.player}
                        hand={this.state.hand!}
                        players={this.props.players}
                        playNextHand={() => this.playNextHand()}
                    ></Review>
                );
        }
    }

    render() {
        let completedStageAlert;
        if (this.state.hand) {
            const newStage = this.getHandStage();
            if (newStage !== this.state.handStage) {
                switch (this.state.handStage) {
                    case HandStage.BIDDING:
                        completedStageAlert = (
                            <BiddingCompleteAlert
                                contract={this.state.hand!.contract!}
                                playHand={() => this.setState({handStage: HandStage.PLAYING})}
                            ></BiddingCompleteAlert>);
                        break;
                    case HandStage.PLAYING:
                        completedStageAlert = (
                            <PlayingCompleteAlert
                                reviewHand={() => this.setState({handStage: HandStage.REVIEWING})}
                            ></PlayingCompleteAlert>);
                }
            }
        }

        return (
            <BrandLoadingIndicator resolved={this.state.hand !== null && this.state.handStage !== null}>
                <div className="next-stage-alerts">
                    {completedStageAlert}
                </div>
                {this.getComponentByState(!!completedStageAlert)}
            </BrandLoadingIndicator>
        );
    }
}

export default Table;