import React from 'react';

import {ITable} from "../Types/interface";

import {BrandLoadingIndicator} from "../Components/BrandLoadingIndicator";

import TablePage from "./TablePage";
import {TableService} from "../Services/table.service";

interface State {
    table: ITable | null;
}

class Game extends React.Component<{}, State> {
    private tableService: TableService;

    constructor(props: {}) {
        super(props);

        this.tableService = new TableService();

        this.state = {
            table: null
        };
    }

    componentDidMount() {
        this.tableService.getTable("VizQ2OceGf9UAecwEz2N").subscribe(table => {
            this.setState({table});
        });
    }

    render() {

        return (
            <BrandLoadingIndicator
                resolved={!!this.state.table}
            >
                <TablePage
                    table={this.state.table!}
                    updatePlayerName={(name, player) => this.tableService.updatePlayerName(this.state.table!.id, name, player)}
                ></TablePage>
            </BrandLoadingIndicator>
        );
    }
}

export default Game;
