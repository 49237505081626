import React from 'react';
import {OtherBids, PassBid} from "../Types/interface";

interface Props {
    className?: string
    type: OtherBids;
    disabled?: boolean;

    onClick?: (type: OtherBids) => void;
}

function OtherBid(props: Props) {

    function handleClick() {
        if (props.onClick && !props.disabled) {
            props.onClick(props.type);
        }
    }

    function getColor() {
        if (props.type === PassBid.PASS) {
            return "green";
        }
        return "red"
    }

    return (
        <div className={`bid ${getColor()} ${props.className} ${props.disabled === true ? "disabled" : ""}`} onClick={() => {handleClick()}}>
            <h3>{props.type}</h3>
        </div>
    );
}

export default OtherBid;