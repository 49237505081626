import React from 'react';
import Spinner from "react-bootstrap/Spinner";
import {Logo} from "./Logo";

type Props = {
    resolved: boolean;
}

type State = {
    minimumTimePassed: boolean;
}

export class BrandLoadingIndicator extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            minimumTimePassed: props.resolved
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({minimumTimePassed: true})
        }, 750);
    }

    render() {
        return this.props.resolved && this.state.minimumTimePassed ?
            <>{ this.props.children }</> :
            <div className="brand-loading-indicator">
                <Logo></Logo>
                <h5 className="font-italic">Loading...</h5>
                <Spinner animation="border"></Spinner>
            </div>
            ;
    }

}