import React from 'react';
import PlayerPositionDropdown from "./PlayerPositionDropdown";
import PlayerNameInput from "./PlayerNameInput";
import {IPlayers, PlayerPositionEnum} from "../Types/interface";

type Props = {
    players: IPlayers;
    player: PlayerPositionEnum;
    updatePlayerName: (name: string) => void;
    updatePlayerPosition: (position: PlayerPositionEnum) => void;
}

function TableSettings({players, player, updatePlayerName, updatePlayerPosition}: Props) {
    return (
        <div className="table-settings">
            <h6>Where I sit:</h6>
            <PlayerPositionDropdown
                player={player}
                players={players}
                onPlayerPositionChange={(position) => updatePlayerPosition(position)}
            ></PlayerPositionDropdown>
            <h6 className="settings-spacing">Update my name:</h6>
            <PlayerNameInput
                name={players[player]}
                updateName={(name) => updatePlayerName(name)}
            ></PlayerNameInput>
        </div>);
}

export default TableSettings;