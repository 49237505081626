import React from "react";

import {Switch, Route} from "react-router-dom";

import Header from "./Header";
import Table from "./Table";
import {ITable, PlayerPositionEnum} from "../Types/interface";
import JoinTable from "../Workflow/JoinTable";
import {LocalStorageService} from "../Services/localStorage.service";
import TableSettings from "../Settings/TableSettings";

type State = {
    player: PlayerPositionEnum | null;
}

type Props = {
    table: ITable;
    updatePlayerName: (name: string, position: PlayerPositionEnum) => void;
}

class TablePage extends React.Component<Props, State> {
    private localService: LocalStorageService;

    constructor(props: Props) {
        super(props);

        this.localService = new LocalStorageService();

        this.state = {
            player: this.localService.getPlayerPosition(this.props.table.players)
        }
    }

    componentDidUpdate(prevProps: Readonly<{ }>, prevState: Readonly<State>) {
        if (this.state.player !== prevState.player && this.state.player !== null) {
            this.localService.savePlayerPosition(this.state.player);
            if (this.props.table.players[this.state.player] !== undefined) {
                this.localService.savePlayerName(this.props.table.players[this.state.player]!);
            }
        }
    }

    private async playerJoinedTable(playerPosition: PlayerPositionEnum, name: string | null) {
        this.setState({player: playerPosition});

        if (name !== null) {
            await this.updatePlayerName(playerPosition, name);
        } else if (this.props.table.players[playerPosition] !== undefined) {
            this.localService.savePlayerName(this.props.table.players[playerPosition]!);
        }
    }

    private async updatePlayerName(player: PlayerPositionEnum, name: string) {
        await this.props.updatePlayerName(name, player);
        this.localService.savePlayerName(name);
    }

    render() {
        return (
            <>
                { this.state.player !== null ?
                    <>
                        <Header
                            table={this.props.table}
                        ></Header>
                        <div className="game-area">
                            <Switch>
                                <Route path="/settings">
                                    <TableSettings
                                        players={this.props.table.players}
                                        player={this.state.player}
                                        updatePlayerName={(name) => this.updatePlayerName(this.state.player!, name)}
                                        updatePlayerPosition={(position) => this.setState({player: position})}
                                    ></TableSettings>
                                </Route>
                                <Route path="/">
                                    <Table
                                        player={this.state.player}
                                        players={this.props.table.players}
                                        table={this.props.table}
                                    ></Table>
                                </Route>
                            </Switch>
                        </div>
                    </>
                    :
                    <JoinTable
                        table={this.props.table}
                        joined={(position, name) => this.playerJoinedTable(position, name)}
                    ></JoinTable>
                }
            </>
        )
    }
}

export default TablePage;