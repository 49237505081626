import React from 'react';

import {ICard} from "../Types/interface";

import CardNumber from "./CardNumber";
import SuitIcon from "./SuitIcon";

interface Props {
    card?: ICard
    className?: string
}

function TableCard(props: Props) {
    if (props.card) {
        return (
            <div className={`table-card`}>
                <CardNumber
                    className={`table-number ${props.className ? props.className : ""}`}
                    number={props.card.number}
                ></CardNumber>
                <SuitIcon
                    className={`table-suit ${props.className ? props.className : ""}`}
                    suit={props.card.suit}
                ></SuitIcon>
            </div>);
    } else {
        return <div className={`table-card ${props.className ? props.className : ""}`}></div>;
    }
}

export default TableCard;
