import React from 'react';

import {ICard} from "../Types/interface";
import CardNumber from "./CardNumber";
import SuitIcon from "./SuitIcon";

interface Props {
    card: ICard
    handleCardClicked?: (card: ICard) => void;
    className?: string;
}

function HandCard(props: Props) {

    function handleClick() {
        if (props.handleCardClicked) {
            props.handleCardClicked(props.card);
        }
    }

    return (
        <div className={`${props.className ? props.className : ""} hand-card ${!!props.handleCardClicked ? "clickable-hand-card": ""}`} onClick={handleClick}>
            <CardNumber className="hand-number" number={props.card.number}></CardNumber>
            <SuitIcon
                className="hand-suit"
                suit={props.card.suit}
            ></SuitIcon>
        </div>
    );
}

export default HandCard;
