import React from 'react';

import {IPlayers, PlayerPositionEnum} from "../Types/interface";
import Alert from "react-bootstrap/Alert";

interface Props {
    players: IPlayers;
    playerWithTurn: PlayerPositionEnum;

    closeAlert: () => void;
}

function WrongTurn(props: Props) {
        return (
            <Alert variant="warning" onClose={() => props.closeAlert()} dismissible>
                {`It's not your turn. It is currently ${props.players[props.playerWithTurn] || props.playerWithTurn}'s turn.`}
            </Alert>
        );
}

export default WrongTurn;

