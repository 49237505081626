import React from 'react';

import Navbar from "react-bootstrap/Navbar";
import {Logo} from "../Components/Logo";
import Nav from "react-bootstrap/Nav";

import settings from "../Resources/Settings_white.svg";
import {Link} from "react-router-dom";
import {ITable} from "../Types/interface";
import Button from "react-bootstrap/Button";
import {HandService} from "../Services/hand.service";

interface Props {
    table: ITable;
}

class Header extends React.Component<Props, {}> {

    private handService: HandService;
    constructor(props: Props) {
        super(props);

        this.handService = new HandService();
    }

    render() {
        return (<Navbar bg="secondary" variant="dark">
            <Navbar.Brand>
                <Link to="/" className="header-logo">
                    <Logo size="small"></Logo>
                </Link>
            </Navbar.Brand>
            <Button variant="primary" onClick={() => this.handService.cancelHand(this.props.table)}>
                Redeal Hand (only one person click, then everyone refresh their page)
            </Button>
            <Nav className="nav-icons">
                <Nav.Item>
                    <Link to="/settings">
                        <img
                            alt=""
                            src={settings}
                            height="30"
                            width="30"
                        />
                    </Link>
                </Nav.Item>
            </Nav>
        </Navbar>);
    }
}

export default Header;
