import React from 'react';
import {NoTrumpEnum, SuitEnum} from "../Types/interface";
import SuitIcon from "../Card/SuitIcon";

interface Props {
    number: number,
    suit: SuitEnum | NoTrumpEnum,
    boxColor?: boolean;
    disabled?: boolean;

    onClick?: (suit: SuitEnum | NoTrumpEnum, number: number) => void;

    className?: string;
}

function ContractBid(props: Props) {
    let color;
    if (props.boxColor) {
        switch(props.suit) {
            case SuitEnum.Spade:
                color = "blue";
                break;
            case SuitEnum.Heart:
                color = "red";
                break;
            case SuitEnum.Diamond:
                color = "orange";
                break;
            case SuitEnum.Club:
                color = "green";
                break;
        }
    }

    function clickHandler() {
        if (props.onClick && !props.disabled) {
            props.onClick(props.suit, props.number);
        }
    }

    return (
        <div className={`bid ${props.className} ${props.disabled === true ? "disabled" : ""}`} onClick={() => clickHandler()}>
            <h3 className={color}>{props.number}</h3>
            {props.suit !== NoTrumpEnum.NoTrump ?
                <SuitIcon
                    suit={props.suit}
                    colour={color}
                    className="bid-suit"
                ></SuitIcon>
                :
                <h3>NT</h3>
            }
        </div>
    );
}

export default ContractBid;