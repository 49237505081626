import React from 'react';
import {
    DoubleBids,
    IBid,
    IContractBid,
    IOtherBid, IPlayers,
    NoTrumpEnum,
    PassBid,
    PlayerPositionEnum,
    SuitEnum
} from "../Types/interface";
import ContractBid from "./ContractBid";
import OtherBid from "./OtherBid";
import {PlayerPosition} from "../Types/PlayerPosition";
import {isContractBid, isOtherBid} from "../Utils/common";
import WrongTurn from "../Alerts/WrongTurn";

interface Props {
    player: PlayerPositionEnum;
    players: IPlayers;
    playerWithTurn: PlayerPositionEnum;
    bids: IBid[] | null;
    disabled: boolean;
    onBidClick: (bid: IBid) => void;
}

interface State {
    showAlert: boolean;
}

class BiddingBox extends React.Component<Props, State> {
    private readonly POSSIBLE_SUIT_BIDS = [1, 2, 3, 4, 5, 6, 7];
    private readonly POSSIBLE_SUITS = [NoTrumpEnum.NoTrump, SuitEnum.Spade, SuitEnum.Heart, SuitEnum.Diamond, SuitEnum.Club];

    constructor(props: Props) {
        super(props);

        this.state = {
            showAlert: false
        };
    }

    static getDerivedStateFromProps(nextProps: Props, prevState: State): Partial<State> | null {
        if (nextProps.playerWithTurn === nextProps.player && prevState.showAlert) {
            return { showAlert: false };
        }
        return null;
    }

    private possibleBid(suit: SuitEnum | NoTrumpEnum.NoTrump, tricks: number, lastContractBid: IContractBid | undefined) {
        if (lastContractBid) {
            if (lastContractBid.tricks > tricks) {
                return false;
            } else if (lastContractBid.tricks < tricks) {
                return true;
            } else {
                if (lastContractBid.suit === NoTrumpEnum.NoTrump) {
                    return false;
                } else if (lastContractBid.suit >= suit) {
                    return false;
                } else {
                    return true;
                }
            }
        }
        return true;
    }

    private handleClick(bid: IBid) {
        if (this.props.playerWithTurn === this.props.player) {
            this.props.onBidClick(bid);
        } else {
            this.setState({ showAlert: true });
        }
    }

    private canRedouble(doubleBid: IOtherBid | undefined) {
        if (doubleBid !== undefined) {
            const doublePlayer = new PlayerPosition(doubleBid.player);
            const opponent1 = doublePlayer.getPlayerToLeft();
            const opponent2 = doublePlayer.getPlayerToRight();
            if (opponent1 === this.props.player || opponent2 === this.props.player) {
                return true;
            }
        }

        return false;
    }

    render() {
        const currentPlayer = new PlayerPosition(this.props.player);

        let lastContractBid: IContractBid | undefined;
        let doubleBid: IOtherBid | undefined;
        if (this.props.bids) {
            for (let i = this.props.bids.length - 1; i >= 0; i--) {
                if (isContractBid(this.props.bids[i])) {
                    lastContractBid = this.props.bids[i] as IContractBid;
                    break;
                } else if (doubleBid === undefined && isOtherBid(this.props.bids[i])) {
                    const bid = this.props.bids[i] as IOtherBid;
                    if (bid.otherBid === DoubleBids.DOUBLE) {
                        doubleBid = bid;
                    }
                }
            }
        }

        return (
            <div className="bidding-box">
                {this.state.showAlert &&
                <WrongTurn
                    players={this.props.players}
                    playerWithTurn={this.props.playerWithTurn}
                    closeAlert={() => this.setState({ showAlert: false })}
                ></WrongTurn>
                }
                {this.POSSIBLE_SUIT_BIDS.map(num => {
                    return <div className="bidding-box-row" key={num}>
                        {
                            this.POSSIBLE_SUITS.map(suit =>
                                <ContractBid
                                    className="bidding-box-card-contract"
                                    number={num}
                                    suit={suit}
                                    boxColor={true}
                                    disabled={!this.possibleBid(suit, num, lastContractBid) || this.props.disabled}
                                    onClick={(suit, number) => {
                                        this.handleClick({player: this.props.player, suit, tricks: number})
                                    }}
                                    key={`${suit}_${num}`}
                                ></ContractBid>
                            )
                        }
                    </div>
                })}
                <div className="bidding-box-row">
                    <OtherBid
                        className="bidding-box-card-double"
                        disabled={lastContractBid === undefined || lastContractBid.player === currentPlayer.getPartner() || this.props.disabled}
                        type={DoubleBids.DOUBLE}
                        onClick={(type) => this.handleClick({player: this.props.player, otherBid: type})}
                    ></OtherBid>
                    <OtherBid
                        className="bidding-box-card-double"
                        disabled={!this.canRedouble(doubleBid) || this.props.disabled}
                        type={DoubleBids.DOUBLE_DOUBLE}
                        onClick={(type) => this.handleClick({player: this.props.player, otherBid: type})}
                    ></OtherBid>
                </div>
                <div className="bidding-box-row">
                    <OtherBid
                        className="bidding-box-card-pass"
                        type={PassBid.PASS}
                        onClick={(type) => this.handleClick({player: this.props.player, otherBid: type})}
                        disabled={this.props.disabled}
                    ></OtherBid>
                </div>
            </div>
        );
    }
}

export default BiddingBox;