import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAtlflCZpivnByGZFlGxRLQRvgyYh6y__8",
    authDomain: "bridge-57a9b.firebaseapp.com",
    databaseURL: "https://bridge-57a9b.firebaseio.com",
    projectId: "bridge-57a9b",
    storageBucket: "bridge-57a9b.appspot.com",
    messagingSenderId: "971102315922",
    appId: "1:971102315922:web:24ba4be04ab15376ff4d4b"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export default db;