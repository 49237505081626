import React from 'react';

import {SuitEnum} from "../Types/interface";
import spade from "../Resources/Spade.svg";
import blue_spade from "../Resources/Spade_blue.svg";
import heart from "../Resources/Heart.svg";
import red_heart from "../Resources/Heart_red.svg";
import diamond from "../Resources/Diamond.svg";
import orange_diamond from "../Resources/Diamond_orange.svg";
import club from "../Resources/Club.svg";
import green_club from "../Resources/Club_green.svg";

interface Props {
    suit: SuitEnum;
    className: string;
    colour?: string;
}

function SuitIcon(props: Props) {
    let suit;
    switch (props.suit) {
        case SuitEnum.Spade:
            if (props.colour === "blue") {
                suit = blue_spade;
            } else {
                suit = spade;
            }
            break;
        case SuitEnum.Heart:
            if (props.colour === "red") {
                suit = red_heart;
            } else {
                suit = heart;
            }
            break;
        case SuitEnum.Diamond:
            if (props.colour === "orange") {
                suit = orange_diamond;
            } else {
                suit = diamond;
            }
            break;
        case SuitEnum.Club:
            if (props.colour === "green") {
                suit = green_club;
            } else {
                suit = club;
            }
            break;
    }

    return (
        <img
            className={props.className}
            src={suit}
            alt={SuitEnum[props.suit]}/>
    );
}

export default SuitIcon;
