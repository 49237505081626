import React from 'react';
import SelectList from "../Components/SelectList";
import {IPlayers, PlayerPositionEnum} from "../Types/interface";

type Props = {
    players: IPlayers;
    tablePositionSelected: (position: PlayerPositionEnum) => void;
}

function ChooseTablePosition({players, tablePositionSelected}: Props) {

    const options = [
        {
            id: PlayerPositionEnum.North,
            name: getPositionName(PlayerPositionEnum.North)
        },
        {
            id: PlayerPositionEnum.East,
            name: getPositionName(PlayerPositionEnum.East)
        },
        {
            id: PlayerPositionEnum.South,
            name: getPositionName(PlayerPositionEnum.South)
        },
        {
            id: PlayerPositionEnum.West,
            name: getPositionName(PlayerPositionEnum.West)
        }
    ];

    function getPositionName(position: PlayerPositionEnum) {
        return players[position] ? `${PlayerPositionEnum[position]} (${players[position]})`
            : `${PlayerPositionEnum[position]}`;
    }

    return (
        <SelectList
            options={options}
            onSelect={(position) => tablePositionSelected(position as PlayerPositionEnum)}
        ></SelectList>
    );
}

export default ChooseTablePosition;