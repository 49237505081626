import React from 'react';

import HandCard from "../Card/HandCard";

import {ICard, SuitEnum} from "../Types/interface";
import {getSortedHandBySuit} from "../Utils/hand-helper";

interface Props {
    cards: ICard[];
    playedCard?: (card: ICard) => void;
}

function StraightHand(props: Props) {
    const sortedHand = getSortedHandBySuit(props.cards);
    return (
        <>
            <div className="hand desktop tablet">
                { props.cards.map(c =>
                    props.playedCard ?
                        <HandCard
                            key={`${c.number}_${c.suit}`}
                            card={c}
                            handleCardClicked={(card) => props.playedCard && props.playedCard(card)}
                        ></HandCard>
                        :
                        <HandCard
                            key={`${c.number}_${c.suit}`}
                            card={c}
                        ></HandCard>
                )}
            </div>
            <div className="mobile">
                <div className="by-suit">
                { sortedHand[SuitEnum.Spade].map(c =>
                    props.playedCard ?
                        <HandCard
                            key={`${c.number}_${c.suit}`}
                            card={c}
                            handleCardClicked={(card) => props.playedCard && props.playedCard(card)}
                        ></HandCard>
                        :
                        <HandCard
                            key={`${c.number}_${c.suit}`}
                            card={c}
                        ></HandCard>
                )}
                </div>
                <div className="by-suit">
                { sortedHand[SuitEnum.Heart].map(c =>
                    props.playedCard ?
                        <HandCard
                            key={`${c.number}_${c.suit}`}
                            card={c}
                            handleCardClicked={(card) => props.playedCard && props.playedCard(card)}
                        ></HandCard>
                        :
                        <HandCard
                            key={`${c.number}_${c.suit}`}
                            card={c}
                        ></HandCard>
                )}
                </div>
                <div className="by-suit">
                { sortedHand[SuitEnum.Club].map(c =>
                    props.playedCard ?
                        <HandCard
                            key={`${c.number}_${c.suit}`}
                            card={c}
                            handleCardClicked={(card) => props.playedCard && props.playedCard(card)}
                        ></HandCard>
                        :
                        <HandCard
                            key={`${c.number}_${c.suit}`}
                            card={c}
                        ></HandCard>
                )}
                </div>
                <div className="by-suit">
                { sortedHand[SuitEnum.Diamond].map(c =>
                    props.playedCard ?
                        <HandCard
                            key={`${c.number}_${c.suit}`}
                            card={c}
                            handleCardClicked={(card) => props.playedCard && props.playedCard(card)}
                        ></HandCard>
                        :
                        <HandCard
                            key={`${c.number}_${c.suit}`}
                            card={c}
                        ></HandCard>
                )}
                </div>
            </div>
        </>
    );
}

export default StraightHand;
