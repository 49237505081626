import React, {FunctionComponent} from 'react';
import diamond from "../Resources/Diamond.svg";

type Props = {
    size?: "small";
}

export const Logo: FunctionComponent<Props> = ({size}) => {
    return (
        <div className={`brand-logo logo-${size}`}>
            <img
                alt=""
                src={diamond}
                className={`brand-img logo-${size}`}
            />{' '}
            <h1 className={`brand-logo-text remove-margin logo-${size}`}>KitchenBridge</h1>
        </div>);

}