import db from "./firebase";
import {ReplaySubject} from "rxjs";

import {CARD_DECK, IBid, IContract, IDeal, IHand, ITable, ITrick, PlayerPositionEnum} from "../Types/interface";
import {PlayerPosition} from "../Types/PlayerPosition";

export class HandService {
    private hand$ = new ReplaySubject<IHand>(1);
    private cancelHandListener: () => void = () => {};

    public getHand() {
        return this.hand$;
    }

    public setHandToActive(table: ITable) {
        db.collection("tables")
            .doc(table.id)
            .collection("games")
            .doc(table.activeGame)
            .collection("hands")
            .where("activeHand", "==", true)
            .get().then((doc) => {
                let docId;
                if (!doc.empty) {
                    docId = (doc.docs[0].data() as IHand).id;
                } else {
                    docId = this.dealNewHand(table);
                }
                this.setHandListener(table, docId);
            });
    }

    public cancelHand(table: ITable) {
        db.collection("tables")
            .doc(table.id)
            .collection("games")
            .doc(table.activeGame)
            .collection("hands")
            .where("activeHand", "==", true)
            .get().then((doc) => {
            let docId;
            if (!doc.empty) {
                const hand = doc.docs[0].data() as IHand;
                docId = hand.id;
                this.handComplete(table, docId, hand.dealer);
            }
        });
    }

    private setHandListener(table: ITable, handId: string) {
        this.cancelHandListener();
        this.cancelHandListener = db.collection("tables")
            .doc(table.id)
            .collection("games")
            .doc(table.activeGame)
            .collection("hands")
            .doc(handId)
            .onSnapshot(doc => {
                if (doc.exists) {
                    this.hand$.next(doc.data() as IHand);
                } else {
                    throw new Error("Hand doesn't exist");
                }
            });
    }

    private dealNewHand(table: ITable): string {
        const newHandRef = db.collection("tables")
            .doc(table.id)
            .collection("games")
            .doc(table.activeGame)
            .collection("hands")
            .doc();


        newHandRef.set({
            id: newHandRef.id,
            activeHand: true,
            deal: this.dealHands(),
            tricks: [{ cards: {} }],
            dealer: PlayerPositionEnum.North,
            bids: null
        });

        return newHandRef.id;
    }

    public updateContract(table: ITable, handId: string, contract: IContract) {
        this.updateHand(table, handId, { contract });
    }

    public updateTricks(table: ITable, handId: string, tricks: ITrick[]) {
        this.updateHand(table, handId, { tricks });
    }

    public updateBids(table: ITable, handId: string, bids: IBid[]) {
        this.updateHand(table, handId, { bids });
    }

    public handComplete(table: ITable, handId: string, dealer: PlayerPositionEnum) {
        const newDealer = new PlayerPosition(dealer).getPlayerToRight();
        //TODO: in a firebase transaction
        const newHandRef = db.collection("tables")
            .doc(table.id)
            .collection("games")
            .doc(table.activeGame)
            .collection("hands")
            .doc();
        newHandRef.set({
            id: newHandRef.id,
            activeHand: true,
            deal: this.dealHands(),
            dealer: newDealer,
            tricks: [{ cards: {} }],
            bids: null
        });

        this.updateHand(table, handId, {activeHand: false});
    }

    private updateHand(table: ITable, handId: string, handUpdate: Partial<IHand>) {
        db.collection("tables")
            .doc(table.id)
            .collection("games")
            .doc(table.activeGame)
            .collection("hands")
            .doc(handId).set(handUpdate, { merge: true });
    }

    private shuffleDeck() {
        const newDeck = CARD_DECK.slice(0);

        for (let i = newDeck.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = newDeck[i];
            newDeck[i] = newDeck[j];
            newDeck[j] = temp;
        }

        return newDeck;
    }

    private dealHands(): IDeal {
        const deck = this.shuffleDeck();
        return {
            North: deck.slice(0, 13),
            East: deck.slice(13, 26),
            South: deck.slice(26, 39),
            West: deck.slice(39, 52)
        }
    }
}