import {ITrick, PlayerPositionEnum} from "../Types/interface";

export function calculateTrickWinners(tricks: ITrick[]) {
    let northSouthTricks = 0;
    let eastWestTricks = 0;
    tricks.forEach(trick => {
        if (trick.winner !== undefined) {
            if (trick.winner === PlayerPositionEnum.North || trick.winner === PlayerPositionEnum.South) {
                northSouthTricks++;
            } else {
                eastWestTricks++;
            }
        }
    });

    return {
        northSouthTricks,
        eastWestTricks
    }
}