import React from 'react';

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

interface Props {
    reviewHand: () => void;
}

function PlayingCompleteAlert({reviewHand}: Props) {

    return (
        <Alert variant="success">
            <Alert.Heading>Hand Complete!</Alert.Heading>
            <p>All cards have been played. Now it is time to review the hand and see how it went.</p>
            <div className="d-flex justify-content-end">
                <Button onClick={() => reviewHand()} variant="outline-success">
                    Review Hand!
                </Button>
            </div>
        </Alert>
    );
}

export default PlayingCompleteAlert;

