import db from "./firebase";
import {Subject} from "rxjs";

import {ITable, PlayerPositionEnum} from "../Types/interface";

export class TableService {
    private table$ = new Subject<ITable>();

    public getTable(id: string) {
        db.collection("tables").doc(id).onSnapshot((doc) => {
            if (doc.exists) {
                this.table$.next(doc.data() as ITable);
            }
        });

        return this.table$.asObservable();
    }

    public async updatePlayerName(tableId: string, name: string, player: PlayerPositionEnum) {
        const update: Partial<ITable> = { players: {}};
        update.players![player] = name;
        await db.collection("tables").doc(tableId).set(update, { merge: true });
    }
}