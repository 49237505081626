import React from 'react';

import {ICard, ITrick, PlayerPositionEnum} from "../Types/interface";
import EastWestDummyHand from "./EastWestDummyHand";
import NorthSouthDummyHand from "./NorthSouthDummyHand";
import StraightHand from "./StraightHand";
import {getCardsNotPlayed, getSortedHandBySuit, sortHand} from "../Utils/hand-helper";

interface Props {
    type: HandTypes;
    hand: ICard[];
    tricks?: ITrick[];
    player?: PlayerPositionEnum
}

export enum HandTypes {
    Straight,
    EastWestDummy,
    NorthSouthDummy
}

function NotPlayableHand(props: Props) {

    function getHand() {
        if (props.tricks && props.player) {
            return getCardsNotPlayed(props.hand, props.tricks, props.player);
        } else {
            return props.hand;
        }
    }

    function getComponentByHandType(handType: HandTypes) {
        const hand = getHand();
        switch(handType) {
            case HandTypes.EastWestDummy:
                return (
                    <EastWestDummyHand
                        cards={getSortedHandBySuit(hand)}
                    ></EastWestDummyHand>
                );
            case HandTypes.NorthSouthDummy:
                return (
                    <NorthSouthDummyHand
                        cards={getSortedHandBySuit(hand)}
                    ></NorthSouthDummyHand>
                );
            case HandTypes.Straight:
                return (
                    <StraightHand
                        cards={sortHand(hand)}
                    ></StraightHand>
                );
        }
    }

    return getComponentByHandType(props.type);
}

export default NotPlayableHand;
