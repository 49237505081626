import React from 'react';

import {IHand, IPlayers, PlayerPositionEnum} from "../Types/interface";

import ContractProgress from "../Play/ContractProgress";
import ContractResult from "./ContractResult";
import BiddingGrid from "../Bid/BiddingGrid";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import AllHands from "./AllHands";
import Button from "react-bootstrap/Button";

interface Props {
    player: PlayerPositionEnum;
    hand: IHand;
    players: IPlayers;
    playNextHand: () => void;
};

function Review({player, hand, players, playNextHand}: Props) {
        return (
            <>
                <div className="review-header">
                    <div>
                        <ContractProgress
                            contract={hand.contract!}
                            tricks={hand.tricks}
                        ></ContractProgress>
                        <ContractResult
                            contract={hand.contract!}
                            tricks={hand.tricks}
                        ></ContractResult>
                    </div>
                    <div>
                        <Button className="play-button" onClick={() => playNextHand()}>Play Next Hand</Button>
                    </div>
                </div>
                <Tabs defaultActiveKey="hands" id="review-tabs">
                    <Tab eventKey="hands" title="Hands">
                       <AllHands
                           player={player}
                           players={players}
                           deal={hand.deal}
                       ></AllHands>
                    </Tab>
                    <Tab eventKey="bidding" title="Bidding">
                        <BiddingGrid
                            player={player}
                            players={players}
                            dealer={hand.dealer}
                            bids={hand.bids}
                        ></BiddingGrid>
                    </Tab>
                    <Tab eventKey="tricks" title="Tricks">
                        <h3>TBD...</h3>
                    </Tab>
                </Tabs>
            </>);
}

export default Review;
