import React from 'react';

import {CardNumberEnum} from "../Types/interface";

interface Props {
    number: CardNumberEnum;
    className?: string;
}

function CardNumber(props: Props) {
    let number;
    switch (props.number) {
        case CardNumberEnum.Ace:
            number = "A";
            break;
        case CardNumberEnum.King:
            number = "K";
            break;
        case CardNumberEnum.Queen:
            number = "Q";
            break;
        case CardNumberEnum.Jack:
            number = "J";
            break;
        default:
            number = props.number;
    }

    return <h3 className={props.className}>{ number }</h3>;
}

export default CardNumber;
