import React from 'react';

import PlayerPositionDisplay from "../Game/PlayerPositionDisplay";
import {IBid, IContractBid, IOtherBid, IPlayers, PlayerPositionEnum} from "../Types/interface";
import Table from "react-bootstrap/Table";
import ContractBid from "./ContractBid";
import OtherBid from "./OtherBid";

interface Props {
    player: PlayerPositionEnum;
    players: IPlayers;
    dealer: PlayerPositionEnum;
    bids: IBid[] | null | undefined;
    turn?: PlayerPositionEnum;

    className?: string;
}

function BiddingGrid(props: Props) {
    const biddingPlayers = [PlayerPositionEnum.North, PlayerPositionEnum.East, PlayerPositionEnum.South, PlayerPositionEnum.West];
    let bids: Array<IBid | null> = [];

    if (props.dealer === PlayerPositionEnum.East) {
        bids.push(null);
    } else if (props.dealer === PlayerPositionEnum.South) {
        bids.push(null, null);
    } else if (props.dealer === PlayerPositionEnum.West) {
        bids.push(null, null, null);
    }

    if (props.bids) {
        bids = bids.concat(props.bids);
    }

    const rows = [];
    const numRows = Math.ceil(bids.length / 4);
    for(let row = 0; row < numRows; row++) {
        const startNum = 4*row;

        const columns  = [];
        const numCols = Math.min(startNum + 4, bids.length);
        for (let col = startNum; col < numCols; col++) {
            columns.push(
                <th key={col}>
                    {displayBid(bids[col])}
                </th>
            )
        }

        rows.push(
                <tr key={row}>
                    { columns }
                </tr>
            );
    }

    function displayBid(bid: IBid | null) {
        if (bid !== null) {
            const contractBid = bid as IContractBid;
            if (contractBid.suit !== undefined && contractBid.tricks !== undefined) {
                return <ContractBid
                    suit={contractBid.suit}
                    number={contractBid.tricks}
                ></ContractBid>;
            }
            const otherBid = bid as IOtherBid;
            if (otherBid.otherBid !== undefined) {
                return <OtherBid type={otherBid.otherBid}></OtherBid>;
            }
        }

        return <h3 className="text-center">-</h3>;
    }

    return (
        <div className={props.className}>
            <Table borderless>
                <thead>
                    <tr>
                        {biddingPlayers.map(player =>
                            <th key={player}>
                                <PlayerPositionDisplay
                                    className={player === props.turn ? "primary" : ""}
                                    player={player}
                                    playerName={props.players[player]}
                                    key={player}
                                ></PlayerPositionDisplay>
                            </th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    { rows }
                </tbody>
            </Table>
        </div>
    );
}

export default BiddingGrid;