import React from 'react';

import {IContract, ITrick} from "../Types/interface";

import ListGroup from "react-bootstrap/ListGroup";
import {calculateTrickWinners} from "../Utils/contract-helper";
import ContractAsText from "../Game/ContractAsText";

interface Props {
    contract: IContract;
    tricks: ITrick[];
}

function ContractProgress({contract, tricks}: Props) {
    const {northSouthTricks, eastWestTricks} = calculateTrickWinners(tricks);

    return (
        <ListGroup horizontal>
            <ListGroup.Item className="cp-contract">
                <ContractAsText contract={contract}></ContractAsText>
            </ListGroup.Item>
            <ListGroup.Item>NS: {northSouthTricks}</ListGroup.Item>
            <ListGroup.Item>EW: {eastWestTricks}</ListGroup.Item>
        </ListGroup>
    );
}

export default ContractProgress;