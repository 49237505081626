export enum SuitEnum {
    Club,
    Diamond,
    Heart,
    Spade
}

export enum NoTrumpEnum {
    NoTrump = 4
}

export enum PlayerPositionEnum {
    North = "North",
    East = "East",
    South = "South",
    West = "West"
}

export enum CardNumberEnum {
    Two=2,
    Three=3,
    Four=4,
    Five=5,
    Six=6,
    Seven=7,
    Eight=8,
    Nine=9,
    Ten=10,
    Jack=11,
    Queen=12,
    King=13,
    Ace=14
}

export interface ICard {
    number: CardNumberEnum;
    suit: SuitEnum;
}

export type ISortedBySuitHand = {
    [position in SuitEnum]: ICard[];
}

export type IDeal = {
    [position in PlayerPositionEnum]: ICard[];
}

export type IPlayers = {
    [position in PlayerPositionEnum]?: string;
}

export interface ITrick {
    cards: ITrickCards;
    lead?: PlayerPositionEnum;
    winner?: PlayerPositionEnum;
};

export type ITrickCards = {
    [position in PlayerPositionEnum]?: ICard;
}

type ContractSuits = SuitEnum | NoTrumpEnum;

export interface IContract {
    suit: ContractSuits,
    tricks: number,
    player: PlayerPositionEnum,
    double: DoubleBids | null
}

export enum DoubleBids {
    DOUBLE = "X",
    DOUBLE_DOUBLE = "XX"
}

export enum PassBid {
    PASS = "PASS"
}

export type OtherBids = DoubleBids | PassBid;

export interface IContractBid {
    player: PlayerPositionEnum
    suit: ContractSuits,
    tricks: number
}

export interface IOtherBid {
    player: PlayerPositionEnum,
    otherBid: OtherBids
}

export type IBid = IContractBid | IOtherBid;

export interface IHand {
    id: string;
    activeHand: boolean;
    deal: IDeal;
    tricks: ITrick[];
    contract?: IContract | null;
    dealer: PlayerPositionEnum;
    bids: IBid[] | null;
}

export interface ITable {
    id: string;
    activeGame: string;
    players: IPlayers;
}

export const CARD_DECK: ICard[] = [
    { number: CardNumberEnum.Two, suit: SuitEnum.Spade },
    { number: CardNumberEnum.Three, suit: SuitEnum.Spade },
    { number: CardNumberEnum.Four, suit: SuitEnum.Spade },
    { number: CardNumberEnum.Five, suit: SuitEnum.Spade },
    { number: CardNumberEnum.Six, suit: SuitEnum.Spade },
    { number: CardNumberEnum.Seven, suit: SuitEnum.Spade },
    { number: CardNumberEnum.Eight, suit: SuitEnum.Spade },
    { number: CardNumberEnum.Nine, suit: SuitEnum.Spade },
    { number: CardNumberEnum.Ten, suit: SuitEnum.Spade },
    { number: CardNumberEnum.Jack, suit: SuitEnum.Spade },
    { number: CardNumberEnum.Queen, suit: SuitEnum.Spade },
    { number: CardNumberEnum.King, suit: SuitEnum.Spade },
    { number: CardNumberEnum.Ace, suit: SuitEnum.Spade },

    { number: CardNumberEnum.Two, suit: SuitEnum.Heart },
    { number: CardNumberEnum.Three, suit: SuitEnum.Heart },
    { number: CardNumberEnum.Four, suit: SuitEnum.Heart },
    { number: CardNumberEnum.Five, suit: SuitEnum.Heart },
    { number: CardNumberEnum.Six, suit: SuitEnum.Heart },
    { number: CardNumberEnum.Seven, suit: SuitEnum.Heart },
    { number: CardNumberEnum.Eight, suit: SuitEnum.Heart },
    { number: CardNumberEnum.Nine, suit: SuitEnum.Heart },
    { number: CardNumberEnum.Ten, suit: SuitEnum.Heart },
    { number: CardNumberEnum.Jack, suit: SuitEnum.Heart },
    { number: CardNumberEnum.Queen, suit: SuitEnum.Heart },
    { number: CardNumberEnum.King, suit: SuitEnum.Heart },
    { number: CardNumberEnum.Ace, suit: SuitEnum.Heart },

    { number: CardNumberEnum.Two, suit: SuitEnum.Diamond },
    { number: CardNumberEnum.Three, suit: SuitEnum.Diamond },
    { number: CardNumberEnum.Four, suit: SuitEnum.Diamond },
    { number: CardNumberEnum.Five, suit: SuitEnum.Diamond },
    { number: CardNumberEnum.Six, suit: SuitEnum.Diamond },
    { number: CardNumberEnum.Seven, suit: SuitEnum.Diamond },
    { number: CardNumberEnum.Eight, suit: SuitEnum.Diamond },
    { number: CardNumberEnum.Nine, suit: SuitEnum.Diamond },
    { number: CardNumberEnum.Ten, suit: SuitEnum.Diamond },
    { number: CardNumberEnum.Jack, suit: SuitEnum.Diamond },
    { number: CardNumberEnum.Queen, suit: SuitEnum.Diamond },
    { number: CardNumberEnum.King, suit: SuitEnum.Diamond },
    { number: CardNumberEnum.Ace, suit: SuitEnum.Diamond },

    { number: CardNumberEnum.Two, suit: SuitEnum.Club },
    { number: CardNumberEnum.Three, suit: SuitEnum.Club },
    { number: CardNumberEnum.Four, suit: SuitEnum.Club },
    { number: CardNumberEnum.Five, suit: SuitEnum.Club },
    { number: CardNumberEnum.Six, suit: SuitEnum.Club },
    { number: CardNumberEnum.Seven, suit: SuitEnum.Club },
    { number: CardNumberEnum.Eight, suit: SuitEnum.Club },
    { number: CardNumberEnum.Nine, suit: SuitEnum.Club },
    { number: CardNumberEnum.Ten, suit: SuitEnum.Club },
    { number: CardNumberEnum.Jack, suit: SuitEnum.Club },
    { number: CardNumberEnum.Queen, suit: SuitEnum.Club },
    { number: CardNumberEnum.King, suit: SuitEnum.Club },
    { number: CardNumberEnum.Ace, suit: SuitEnum.Club },
]