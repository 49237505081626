import React from 'react';

import HandCard from "../Card/HandCard";
import {ISortedBySuitHand, SuitEnum} from "../Types/interface";

interface Props {
    cards: ISortedBySuitHand;
}

function EastWestDummyHand(props: Props) {
    const suits = [SuitEnum.Spade, SuitEnum.Heart, SuitEnum.Club, SuitEnum.Diamond];

    return (
        <div className="ew-dummy-hand">
            { suits.map(suit =>
                <div className="ew-dummy-suit" key={suit}>
                    { props.cards[suit].map(c =>
                        <HandCard
                            key={`${c.number}_${c.suit}`}
                            card={c}
                        ></HandCard>)}
                </div>
            )}
        </div>
    );
}

export default EastWestDummyHand;
